<template>
	<div class="weather-map">
		<!-- ローディング -->
		<transition name="fade">
			<redraw-progress v-if="isLoading" />
		</transition>
		<!-- /ローディング -->

		<!-- エラー処理 -->
		<transition name="fade">
			<common-error-alert @reload="reload" v-show="isError" />
		</transition>
		<!-- /エラー処理 -->

		<!-- コンテンツ領域 -->
		<div class="weather-map__wrapper" id='js_weathermapWrapper'>
			<canvas class='weather-map__canvas' width="640" height="1136" id='js_canvas'></canvas>
			<canvas class='weather-map__background' width="640" height="1136" id="js_bgCanvas"></canvas>
		</div>
		<!-- /コンテンツ領域 -->
	</div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_WEATHER_DATA } from '../store/modules/weatherMap/mutation-types'

// Compornents
import RedrawProgress from '../components/Molecules/RedrawProgress'
import CommonErrorAlert from '../components/Molecules/CommonErrorAlert'

// アプリケーション共通定数
import cmnConst from '@/assets/js/constant.js'

// 定数
const createjs = window.createjs
const PERIOD = 3	// 期間（きょう、明日、明後日）
const ELEMENT_MAX = 5	// カテゴリ最大値
const CARD_PROP_ARRAY = [	// 全国カード表示位置
	{ no: 0, chitenCode: '01101', cityName: 'sapporo', x: 419, y: 354, isUnder: false },
	{ no: 11, chitenCode: '04101', cityName: 'sendai', x: 567, y: 650, isUnder: true },
	{ no: 20, chitenCode: '13101', cityName: 'tokyo', x: 492, y: 802, isUnder: true },
	{ no: 13, chitenCode: '15103', cityName: 'niigata', x: 354, y: 490, isUnder: false },
	{ no: 24, chitenCode: '17201', cityName: 'kanazawa', x: 260, y: 554, isUnder: false },
	{ no: 28, chitenCode: '23106', cityName: 'nagoya', x: 395, y: 826, isUnder: true },
	{ no: 34, chitenCode: '27128', cityName: 'osaka', x: 298, y: 847, isUnder: true },
	{ no: 39, chitenCode: '34101', cityName: 'hiroshima', x: 168, y: 554, isUnder: false },
	{ no: 43, chitenCode: '39201', cityName: 'kouchi', x: 208, y: 860, isUnder: true },
	{ no: 45, chitenCode: '40133', cityName: 'fukuoka', x: 78, y: 606, isUnder: false },
	{ no: 53, chitenCode: '47201', cityName: 'naha', x: 264, y: 384, isUnder: false }
]
const CARD_PROP_ARRAY_HOKKAI = [	// 北海道カード表示位置
	{ no: 0, chitenCode: '01101', cityName: 'sapporo', x: 122, y: 548, isUnder: false },
	{ no: 1, chitenCode: '01214', cityName: 'wakkanai', x: 218, y: 360, isUnder: false },
	{ no: 2, chitenCode: '01204', cityName: 'asahikawa', x: 360, y: 612, isUnder: true },
	{ no: 3, chitenCode: '01211', cityName: 'abashiri', x: 548, y: 460, isUnder: false },
	{ no: 4, chitenCode: '01206', cityName: 'kushiro', x: 502, y: 770, isUnder: true },
	{ no: 5, chitenCode: '01205', cityName: 'muroran', x: 300, y: 784, isUnder: true },
	{ no: 6, chitenCode: '01202', cityName: 'hakodate', x: 190, y: 814, isUnder: true }
]
const CARD_PROP_ARRAY_TOHOKU = [	// 東北カード表示位置
	{ no: 7, chitenCode: '02201', cityName: 'aomori', x: 368, y: 434, isUnder: false },
	{ no: 8, chitenCode: '05201', cityName: 'akita', x: 252, y: 582, isUnder: false },
	{ no: 9, chitenCode: '03201', cityName: 'morioka', x: 514, y: 626, isUnder: false },
	{ no: 10, chitenCode: '06201', cityName: 'yamagata', x: 162, y: 710, isUnder: true },
	{ no: 11, chitenCode: '04101', cityName: 'sendai', x: 440, y: 756, isUnder: true },
	{ no: 12, chitenCode: '07201', cityName: 'fukushima', x: 318, y: 838, isUnder: true }
]
const CARD_PROP_ARRAY_KANTO = [	// 関東甲信越カード表示位置
	{ no: 13, chitenCode: '15103', cityName: 'niigata', x: 213, y: 368, isUnder: false },
	{ no: 14, chitenCode: '20201', cityName: 'nagano', x: 106, y: 554, isUnder: false },
	{ no: 15, chitenCode: '19201', cityName: 'koufu', x: 126, y: 816, isUnder: true },
	{ no: 16, chitenCode: '10201', cityName: 'maebashi', x: 329, y: 460, isUnder: false },
	{ no: 17, chitenCode: '09201', cityName: 'utsunomiya', x: 482, y: 514, isUnder: false },
	{ no: 18, chitenCode: '08201', cityName: 'mito', x: 578, y: 618, isUnder: false },
	{ no: 19, chitenCode: '11101', cityName: 'saitama', x: 344, y: 596, isUnder: true },
	{ no: 20, chitenCode: '13101', cityName: 'tokyo', x: 316, y: 729, isUnder: true },
	{ no: 21, chitenCode: '12101', cityName: 'chiba', x: 476, y: 906, isUnder: true },
	{ no: 22, chitenCode: '14104', cityName: 'yokohama', x: 290, y: 906, isUnder: true }
]
const CARD_PROP_ARRAY_KANSAI = [	// 関西カード表示位置
	{ no: 23, chitenCode: '16201', cityName: 'toyama', x: 576, y: 527, isUnder: false },
	{ no: 24, chitenCode: '17201', cityName: 'kanazawa', x: 480, y: 353, isUnder: false },
	{ no: 25, chitenCode: '18201', cityName: 'fukui', x: 375, y: 353, isUnder: false },
	{ no: 26, chitenCode: '21201', cityName: 'gifu', x: 496, y: 684, isUnder: false },
	{ no: 27, chitenCode: '22101', cityName: 'sizuoka', x: 436, y: 904, isUnder: true },
	{ no: 28, chitenCode: '23106', cityName: 'nagoya', x: 378, y: 768, isUnder: true },
	{ no: 29, chitenCode: '25201', cityName: 'ootsu', x: 390, y: 575, isUnder: false },
	{ no: 30, chitenCode: '26104', cityName: 'kyoto', x: 274, y: 489, isUnder: false },
	{ no: 31, chitenCode: '28110', cityName: 'kobe', x: 105, y: 499, isUnder: true },
	{ no: 32, chitenCode: '24201', cityName: 'tsu', x: 224, y: 904, isUnder: true },
	{ no: 33, chitenCode: '29201', cityName: 'nara', x: 236, y: 729, isUnder: true },
	{ no: 34, chitenCode: '27128', cityName: 'osaka', x: 146, y: 710, isUnder: true },
	{ no: 35, chitenCode: '30201', cityName: 'wakayama', x: 81, y: 868, isUnder: true }
]
const CARD_PROP_ARRAY_CHUGOKU = [	// 中国・四国カード表示位置
	{ no: 36, chitenCode: '31201', cityName: 'tottori', x: 548, y: 382, isUnder: false },
	{ no: 37, chitenCode: '33101', cityName: 'okayama', x: 422, y: 527, isUnder: false },
	{ no: 38, chitenCode: '32201', cityName: 'matsue', x: 300, y: 381, isUnder: false },
	{ no: 39, chitenCode: '34101', cityName: 'hiroshima', x: 314, y: 576, isUnder: false },
	{ no: 40, chitenCode: '35203', cityName: 'yamaguchi', x: 132, y: 471, isUnder: false },
	{ no: 41, chitenCode: '37201', cityName: 'takamatsu', x: 516, y: 650, isUnder: false },
	{ no: 42, chitenCode: '36201', cityName: 'tokushima', x: 486, y: 844, isUnder: true },
	{ no: 43, chitenCode: '39201', cityName: 'kouchi', x: 238, y: 882, isUnder: true },
	{ no: 44, chitenCode: '38201', cityName: 'matsuyama', x: 165, y: 728, isUnder: true }
]
const CARD_PROP_ARRAY_KYUSHU = [	// 九州カード表示位置
	{ no: 45, chitenCode: '40133', cityName: 'fukuoka', x: 478, y: 370, isUnder: false },
	{ no: 46, chitenCode: '41201', cityName: 'saga', x: 274, y: 379, isUnder: false },
	{ no: 47, chitenCode: '42201', cityName: 'nagasaki', x: 132, y: 537, isUnder: false },
	{ no: 48, chitenCode: '44201', cityName: 'ooita', x: 450, y: 604, isUnder: false },
	{ no: 49, chitenCode: '43101', cityName: 'kumamoto', x: 273, y: 653, isUnder: true },
	{ no: 50, chitenCode: '45201', cityName: 'miyazaki', x: 388, y: 828, isUnder: true },
	{ no: 51, chitenCode: '46201', cityName: 'kagoshima', x: 184, y: 832, isUnder: true }
]
const CARD_PROP_ARRAY_OKINAWA = [	// 沖縄諸島カード表示位置
	{ no: 52, chitenCode: '47209', cityName: 'nago', x: 526, y: 590, isUnder: true },
	{ no: 53, chitenCode: '47201', cityName: 'naha', x: 338, y: 564, isUnder: false },
	{ no: 54, chitenCode: '47361', cityName: 'kumezima', x: 282, y: 428, isUnder: false },
	{ no: 55, chitenCode: '47214', cityName: 'miyakozima', x: 330, y: 754, isUnder: true },
	{ no: 56, chitenCode: '47207', cityName: 'ishigakizima', x: 151, y: 768, isUnder: true },
	{ no: 57, chitenCode: '47382', cityName: 'yonagunizima', x: 112, y: 477, isUnder: false },
	{ no: 58, chitenCode: '47357', cityName: 'minamidaito', x: 511, y: 753, isUnder: true }
]
const FPS = 30
const TEXT_PRIMARY = cmnConst.BLACKGREY
const TEXT_SECONDARY = cmnConst.WHITE
const BACKGROUND_TEMPERATURE_BEST = cmnConst.SANDYBROWN
const BACKGROUND_TEMPERATURE_WORST = cmnConst.POWDERBLUE
const SPEED_LEVEL1 = cmnConst.MINT
const SPEED_LEVEL2 = cmnConst.SANDYBROWN
const SPEED_LEVEL3 = cmnConst.SALMON
const SPEED_LEVEL4 = cmnConst.VIOLET

export default {
	name: 'WeatherMap',
	components: {
		RedrawProgress,
		CommonErrorAlert
	},
	data () {
		return {
			canvas: null,
			bgCanvas: null,
			canvasStage: null,
			bgCanvasStage: null,
			variableContainer: null,
			weatherMapWrapper: null,
			resolution: '@2x',
			scale: 1,
			queue: null,
			containerArray: [],
			dateButtonArray: [],
			elementButtonArray: [],
			hitAreaArray: [],
			dateNum: 0,
			elementNum: 0,
			eventDetector: null,
			isError: false,
			isLoading: false,
			isAnimation: false,
			selectMap: 0,
			mapSprite: null,
			celSpriteSheet: null,
			hitAreaSpriteSheet: null,
			buttonSpriteSheet: null
		}
	},

	mounted () {
		// 要素幅を取得し、canvasをリサイズしてから初期化
		this.canvas = document.getElementById('js_canvas')
		this.bgCanvas = document.getElementById('js_bgCanvas')
		this.weatherMapWrapper = document.getElementById('js_weathermapWrapper')
		this.onResize()

		// canvas領域の初期化と画像のプリロード
		this.initCanvas()
		this.preload()

		// API通信
		// NOTE: 定期的にバックエンドで天気データが更新されるため、Vuexにデータがある、且つ痛みノートの×ボタンで戻ってきた場合も通信する
		this.getMapData()
	},

	computed: {
		// map Vuex getters
		...mapGetters('weatherMap', ['weatherData']),
		...mapGetters('common', ['userId', 'userToken', 'viewMode', 'isWebview', 'premiumType']),

		// 表示しているマップにあわせてAPIレスポンスを加工してデータを返す
		computedMapData () {
			let mapData = []

			if (this.weatherData) {
				let initData = []
				switch (this.selectMap) {
					case 1 :
						// 北海道
						initData = CARD_PROP_ARRAY_HOKKAI
						break
					case 2 :
						// 東北
						initData = CARD_PROP_ARRAY_TOHOKU
						break
					case 3 :
						// 関東甲信越
						initData = CARD_PROP_ARRAY_KANTO
						break
					case 4 :
						// 関西
						initData = CARD_PROP_ARRAY_KANSAI
						break
					case 5 :
						// 中国・四国
						initData = CARD_PROP_ARRAY_CHUGOKU
						break
					case 6 :
						// 九州
						initData = CARD_PROP_ARRAY_KYUSHU
						break
					case 7 :
						// 沖縄諸島
						initData = CARD_PROP_ARRAY_OKINAWA
						break
					default :
						// 全国
						initData = CARD_PROP_ARRAY
						break
				}

				mapData = initData.map(value => {
					const obj = this.weatherData.locations.find(obj => obj.chitenCode === value.chitenCode)
					value.forecasts = obj.forecasts
					return value
				})
			}
			return mapData
		},

		// プリロード用マニフェストを返す
		computedManifest () {
			return [
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/map_bg_sprite${this.resolution}.png`, 'id': 'map_bg_sprite' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/cel_sprite${this.resolution}.png`, 'id': 'cel_sprite' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/hit_area_sprite${this.resolution}.png`, 'id': 'hit_area_sprite' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/btn_sprite${this.resolution}.png`, 'id': 'btn_sprite' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/kaiteki40to65${this.resolution}.png`, 'id': 'kaiteki40to65' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/map_kiatu_icon_lv1${this.resolution}.png`, 'id': 'map_kiatu_icon_lv1' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/map_kiatu_icon_lv2${this.resolution}.png`, 'id': 'map_kiatu_icon_lv2' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/map_kiatu_icon_lv3${this.resolution}.png`, 'id': 'map_kiatu_icon_lv3' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/map_kiatu_icon_lv4${this.resolution}.png`, 'id': 'map_kiatu_icon_lv4' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/map_kiatu_icon_lv5${this.resolution}.png`, 'id': 'map_kiatu_icon_lv5' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_100${this.resolution}.png`, 'id': 'tenki_100' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_101${this.resolution}.png`, 'id': 'tenki_101' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_103${this.resolution}.png`, 'id': 'tenki_103' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_105${this.resolution}.png`, 'id': 'tenki_105' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_111${this.resolution}.png`, 'id': 'tenki_111' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_114${this.resolution}.png`, 'id': 'tenki_114' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_117${this.resolution}.png`, 'id': 'tenki_117' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_200${this.resolution}.png`, 'id': 'tenki_200' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_201${this.resolution}.png`, 'id': 'tenki_201' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_203${this.resolution}.png`, 'id': 'tenki_203' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_205${this.resolution}.png`, 'id': 'tenki_205' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_211${this.resolution}.png`, 'id': 'tenki_211' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_214${this.resolution}.png`, 'id': 'tenki_214' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_217${this.resolution}.png`, 'id': 'tenki_217' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_300${this.resolution}.png`, 'id': 'tenki_300' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_301${this.resolution}.png`, 'id': 'tenki_301' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_302${this.resolution}.png`, 'id': 'tenki_302' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_303${this.resolution}.png`, 'id': 'tenki_303' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_311${this.resolution}.png`, 'id': 'tenki_311' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_313${this.resolution}.png`, 'id': 'tenki_313' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_315${this.resolution}.png`, 'id': 'tenki_315' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_400${this.resolution}.png`, 'id': 'tenki_400' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_401${this.resolution}.png`, 'id': 'tenki_401' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_402${this.resolution}.png`, 'id': 'tenki_402' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_403${this.resolution}.png`, 'id': 'tenki_403' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_411${this.resolution}.png`, 'id': 'tenki_411' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_413${this.resolution}.png`, 'id': 'tenki_413' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_414${this.resolution}.png`, 'id': 'tenki_414' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/tenki_icon${this.resolution}.png`, 'id': 'tenki_icon' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/par${this.resolution}.png`, 'id': 'par' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/kaiteki40to65${this.resolution}.png`, 'id': 'kaiteki40to65' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet00_20${this.resolution}.png`, 'id': 'wet00_20' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet20_25${this.resolution}.png`, 'id': 'wet20_25' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet25_30${this.resolution}.png`, 'id': 'wet25_30' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet30_35${this.resolution}.png`, 'id': 'wet30_35' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet35_40${this.resolution}.png`, 'id': 'wet35_40' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet40_45${this.resolution}.png`, 'id': 'wet40_45' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet45_50${this.resolution}.png`, 'id': 'wet45_50' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet50_55${this.resolution}.png`, 'id': 'wet50_55' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet55_60${this.resolution}.png`, 'id': 'wet55_60' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet60_65${this.resolution}.png`, 'id': 'wet60_65' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet65_70${this.resolution}.png`, 'id': 'wet65_70' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet70_75${this.resolution}.png`, 'id': 'wet70_75' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet75_80${this.resolution}.png`, 'id': 'wet75_80' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet80_85${this.resolution}.png`, 'id': 'wet80_85' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet85_90${this.resolution}.png`, 'id': 'wet85_90' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wet90_99${this.resolution}.png`, 'id': 'wet90_99' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wind_arrow_lv1${this.resolution}.png`, 'id': 'wind_arrow_lv1' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wind_arrow_lv2${this.resolution}.png`, 'id': 'wind_arrow_lv2' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wind_arrow_lv3${this.resolution}.png`, 'id': 'wind_arrow_lv3' },
				{ 'src': `${cmnConst.FRONT_URL}static/weathermap/wind_arrow_lv4${this.resolution}.png`, 'id': 'wind_arrow_lv4' }
			]
		}
	},

	methods: {
		// map Vuex actions
		...mapActions('weatherMap', [SET_WEATHER_DATA]),

		/**
		 * 全国マップデータ取得処理
		 */
		getMapData () {
			// NOTE: user_id、user_tokenなしの場合は全国表示のみのデータが返る
			// NOTE: 地方詳細用データを取得する場合はuser_id、user_tokenを渡す
			this.isLoading = true
			this.SET_WEATHER_DATA({
				csid: 'mmcm',
				user_id: this.userId,
				user_token: this.userToken,
				format: 'json'
			}).then(res => {
				console.log(this.weatherData)
				const timer = setInterval(() => {
					if (!this.isLoading) {
						clearInterval(timer)
						this.canvasMainStart()
					}
				}, 300)
			}).catch(error => {
				// エラー処理
				console.log(error)
				this.isLoading = false
				this.isError = true
			})
		},

		/**
		 * リロード処理
		 */
		reload () {
			this.isError = false
			this.getMapData()
		},

		/**
		 * Canvasの初期化処理
		 */
		initCanvas () {
			this.canvasStage = new createjs.Stage('js_canvas')
			this.canvasStage.clear()
			this.bgCanvasStage = new createjs.Stage('js_bgCanvas')
			this.bgCanvasStage.clear()

			// タッチ操作をサポートしているブラウザならタッチ操作を有効に
			if (createjs.Touch.isSupported()) createjs.Touch.enable(this.canvasStage)

			// tickイベント登録
			createjs.Ticker.timingMode = createjs.Ticker.RAF
			createjs.Ticker.framerate = FPS	// TODO: FPSが変わっておらず、RAFのデフォルト60になっているっぽい。後ほど調査。
		},

		/**
		 * tickerの描画処理
		 */
		loop () {
			this.canvasStage.update()
			this.bgCanvasStage.update()
		},

		/**
		 * 画像のプリロード処理
		 */
		preload () {
			this.queue = new createjs.LoadQueue(true)
			this.queue.setMaxConnections(6)
			this.queue.loadManifest(this.computedManifest, true)

			// LoadQueueでの画像読み込みが完了したら次の処理へ
			this.queue.addEventListener('complete', (e) => {
				this.queue.removeAllEventListeners('complete')
				this.queue.removeAllEventListeners('fileload')
				this.isLoading = false
			})
		},

		/**
		 * Canvasの表示開始処理
		 */
		canvasMainStart () {
			this.dateNum = 0
			this.elementNum = 0

			// URLパラメータがある場合の処理（?date=1&status=weather）
			const urlParamDate = this.$route.query.date
			const urlParamStatus = this.$route.query.status
			const urlParamSelectMap = this.$route.query.selectMap
			if (urlParamDate) this.dateNum = Number(urlParamDate) - 1
			if (urlParamSelectMap) this.selectMap = Number(urlParamSelectMap)
			switch (urlParamStatus) {
				case 'pressure' :
					this.elementNum = 0
					break
				case 'weather' :
					this.elementNum = 1
					break
				case 'temp' :
					this.elementNum = 2
					break
				case 'wind' :
					this.elementNum = 3
					break
				case 'humid' :
					this.elementNum = 4
					break
			}

			// スプライトオブジェクトの初期化
			this.initSprite()

			// 各コンテナの初期化
			this.initBgCanvas()
			this.initContainer()
			this.initButton()
			this.handleMap()
			this.canvasStage.update()
		},

		/**
		 * スプライトの初期化処理
		 */
		initSprite () {
			// スプライトのフレームサイズをまとめて判定
			let spriteSize = null
			if (this.resolution === '@2x') {
				spriteSize = {
					map: { width: 640, height: 1136 },
					cel: { width: 92, height: 130 },
					hitArea: [
						{ width: 243, height: 179 },	// 全国マップ北海道
						{ width: 106, height: 149 },	// 全国マップ東北
						{ width: 145, height: 171 },	// 全国マップ関東
						{ width: 183, height: 206 },	// 全国マップ関西
						{ width: 136, height: 162 },	// 全国マップ中国・四国
						{ width: 99, height: 157 },	// 全国マップ九州
						{ width: 147, height: 79 },	// 全国マップ沖縄
						{ width: 315, height: 322 },	// 北海道マップA
						{ width: 542, height: 290 },	// 東北マップA
						{ width: 516, height: 590 },	// 東北マップB
						{ width: 349, height: 638 },	// 関東マップA
						{ width: 244, height: 777 },	// 関東マップB
						{ width: 193, height: 674 },	// 関西マップA
						{ width: 220, height: 503 },	// 関西マップB
						{ width: 170, height: 531 },	// 中国・四国マップA
						{ width: 117, height: 550 },	// 中国・四国マップB
						{ width: 169, height: 667 },	// 九州マップA
						{ width: 486, height: 258 }	// 沖縄マップA
					],
					btn: [
						{ width: 186, height: 59 },	// 今日、あす、あさって
						{ width: 110, height: 59 }		// 気圧、天気、気温、風向、湿度
					]
				}
			} else {
				spriteSize = {
					map: { width: 960, height: 1704 },
					cel: { width: 138, height: 195 },
					hitArea: [
						{ width: 365, height: 269 },	// 全国マップ北海道
						{ width: 159, height: 224 },	// 全国マップ東北
						{ width: 218, height: 256 },	// 全国マップ関東
						{ width: 275, height: 309 },	// 全国マップ関西
						{ width: 204, height: 243 },	// 全国マップ中国・四国
						{ width: 149, height: 235 },	// 全国マップ九州
						{ width: 221, height: 119 },	// 全国マップ沖縄
						{ width: 471, height: 483 },	// 北海道マップA
						{ width: 812, height: 435 },	// 東北マップA
						{ width: 774, height: 885 },	// 東北マップB
						{ width: 524, height: 956 },	// 関東マップA
						{ width: 364, height: 1164 },	// 関東マップB
						{ width: 289, height: 1010 },	// 関西マップA
						{ width: 330, height: 754 },	// 関西マップB
						{ width: 253, height: 796 },	// 中国・四国マップA
						{ width: 176, height: 825 },	// 中国・四国マップB
						{ width: 253, height: 1001 },	// 九州マップA
						{ width: 728, height: 385 }	// 沖縄マップA
					],
					btn: [
						{ width: 279, height: 89 },	// 今日、あす、あさって
						{ width: 165, height: 89 }		// 気圧、天気、気温、風向、湿度
					]
				}
			}
			// 背景画像のスプライト
			const mapSpriteData = {
				images: [this.queue.getResult('map_bg_sprite')],
				frames: { width: spriteSize.map.width, height: spriteSize.map.height, count: 8, regX: 0, regY: 0, spacing: 0, margin: 0 },
				animations: { all: 0, hokkai: 1, tohoku: 2, kanto: 3, kansai: 4, cyugoku: 5, kyushu: 6, okinawa: 7 }
			}
			const mapSpriteSheet = new createjs.SpriteSheet(mapSpriteData)
			this.mapSprite = new createjs.Sprite(mapSpriteSheet, this.selectMap)

			// 地名カード画像のスプライト
			const celSpriteData = {
				images: [this.queue.getResult('cel_sprite')],
				frames: { width: spriteSize.cel.width, height: spriteSize.cel.height, count: 59, regX: 0, regY: 0, spacing: 0, margin: 0 },
				animations: {}
			}
			let cardArr = []
			cardArr = cardArr.concat(CARD_PROP_ARRAY_HOKKAI, CARD_PROP_ARRAY_TOHOKU, CARD_PROP_ARRAY_KANTO, CARD_PROP_ARRAY_KANSAI, CARD_PROP_ARRAY_CHUGOKU, CARD_PROP_ARRAY_KYUSHU, CARD_PROP_ARRAY_OKINAWA)
			for (let i = 0, len = cardArr.length; i < len; i++) {
				celSpriteData.animations[cardArr[i].cityName] = cardArr[i].no
			}
			this.celSpriteSheet = new createjs.SpriteSheet(celSpriteData)

			// ヒットエリアのスプライト
			const hitAreaSpriteData = {
				images: [this.queue.getResult('hit_area_sprite')],
				frames: [],
				animations: { hokkai: 0, tohoku: 1, kanto: 2, kansai: 3, cyugoku: 4, kyushu: 5, okinawa: 6, hokkaiA: 7, tohokuA: 8, tohokuB: 9, kantoA: 10, kantoB: 11, kansaiA: 12, kansaiB: 13, cyugokuA: 14, cyugokuB: 15, kyushuA: 16, okinawaA: 17 }
			}
			let y = 0
			for (let i = 0, len = spriteSize.hitArea.length; i < len; i++) {
				hitAreaSpriteData.frames.push([0, y, spriteSize.hitArea[i].width, spriteSize.hitArea[i].height, 0, 0, 0])
				// y軸の更新
				y += spriteSize.hitArea[i].height
			}
			this.hitAreaSpriteSheet = new createjs.SpriteSheet(hitAreaSpriteData)

			// ボタンのスプライト
			const buttonSpriteData = {
				images: [this.queue.getResult('btn_sprite')],
				frames: [],
				animations: { btn_date0_off: 0, btn_date0_on: 1, btn_date1_off: 2, btn_date1_on: 3, btn_date2_off: 4, btn_date2_on: 5, btn_element0_off: 6, btn_element0_on: 7, btn_element1_off: 8, btn_element1_on: 9, btn_element2_off: 10, btn_element2_on: 11, btn_element3_off: 12, btn_element3_on: 13, btn_element4_off: 14, btn_element4_on: 15 }
			}
			y = 0
			for (let i = 0, len = (PERIOD + ELEMENT_MAX) * 2; i < len; i++) {
				const index = i < PERIOD * 2 ? 0 : 1
				buttonSpriteData.frames.push([0, y, spriteSize.btn[index].width, spriteSize.btn[index].height, 0, 0, 0])
				// y軸の更新
				y += spriteSize.btn[index].height
			}
			this.buttonSpriteSheet = new createjs.SpriteSheet(buttonSpriteData)
		},

		/**
		 * 背景Canvasの初期化処理
		 */
		initBgCanvas () {
			this.bgCanvasStage.addChild(this.mapSprite)
			this.bgCanvasStage.update()
		},

		/**
		 * マップ表示をハンドルする関数
		 */
		handleMap () {
			switch (this.selectMap) {
				case 1 :	// 北海道
					this.mapSprite.gotoAndStop('hokkai')
					// 東北に切り替えるヒットエリア
					this.setHitArea(2, 'hokkaiA', 0, Math.round(816 * this.scale))
					break
				case 2 :	// 東北
					this.mapSprite.gotoAndStop('tohoku')
					// 北海道に切り替えるヒットエリア
					this.setHitArea(1, 'tohokuA', Math.round(98 * this.scale), 0)
					// 関東に切り替えるヒットエリア
					this.setHitArea(3, 'tohokuB', 0, Math.round(545 * this.scale))
					break
				case 3 :	// 関東甲信越
					this.mapSprite.gotoAndStop('kanto')
					// 東北に切り替えるヒットエリア
					this.setHitArea(2, 'kantoA', Math.round(291 * this.scale), Math.round(26 * this.scale))
					// 関西に切り替えるヒットエリア
					this.setHitArea(4, 'kantoB', 0, Math.round(190 * this.scale))
					break
				case 4 :	// 関西
					this.mapSprite.gotoAndStop('kansai')
					// 関東に切り替えるヒットエリア
					this.setHitArea(3, 'kansaiA', Math.round(448 * this.scale), Math.round(338 * this.scale))
					// 中国・四国に切り替えるヒットエリア
					this.setHitArea(5, 'kansaiB', 0, Math.round(270 * this.scale))
					break
				case 5 :	// 中国・四国
					this.mapSprite.gotoAndStop('cyugoku')
					// 関西に切り替えるヒットエリア
					this.setHitArea(4, 'cyugokuA', Math.round(471 * this.scale), Math.round(354 * this.scale))
					// 九州に切り替えるヒットエリア
					this.setHitArea(6, 'cyugokuB', 0, Math.round(502 * this.scale))
					break
				case 6 :	// 九州
					this.mapSprite.gotoAndStop('kyushu')
					// 中国・四国に切り替えるヒットエリア
					this.setHitArea(5, 'kyushuA', Math.round(471 * this.scale), Math.round(153 * this.scale))
					break
				case 7 :	// 沖縄
					this.mapSprite.gotoAndStop('okinawa')
					break
				default :	// 全国
					this.mapSprite.gotoAndStop('all')
					// 北海道
					this.setHitArea(1, 'hokkai', Math.round(397 * this.scale), Math.round(319 * this.scale))
					// 東北
					this.setHitArea(2, 'tohoku', Math.round(401 * this.scale), Math.round(499 * this.scale))
					// 関東
					this.setHitArea(3, 'kanto', Math.round(355 * this.scale), Math.round(556 * this.scale))
					// 関西
					this.setHitArea(4, 'kansai', Math.round(243 * this.scale), Math.round(565 * this.scale))
					// 中国・四国
					this.setHitArea(5, 'cyugoku', Math.round(127 * this.scale), Math.round(620 * this.scale))
					// 九州
					this.setHitArea(6, 'kyushu', Math.round(48 * this.scale), Math.round(661 * this.scale))
					// 沖縄
					this.setHitArea(7, 'okinawa', Math.round(11 * this.scale), Math.round(337 * this.scale))
					break
			}
		},

		/**
		 * マップのヒットエリアの設定処理
		 */
		setHitArea (areaNo, name, x, y) {
			// ヒットエリアオブジェクト生成
			const hitAreaObj = new createjs.Sprite(this.hitAreaSpriteSheet)
			hitAreaObj.gotoAndStop(name)
			hitAreaObj.x = x
			hitAreaObj.y = y
			// 可変コンテナにヒットエリアを追加
			this.variableContainer.addChild(hitAreaObj)
			this.variableContainer.setChildIndex(hitAreaObj, 0)
			this.hitAreaArray.push(hitAreaObj)
			hitAreaObj.alpha = 0.01
			hitAreaObj.addEventListener('click', (e) => {
				if (!this.isAnimation) {
					this.startAnimation()
					createjs.Tween.get(hitAreaObj, { override: true })
						.to({ alpha: 1 }, 300, createjs.Ease.circIn)
						.call(() => {
							this.switchMap(areaNo)
						})
				}
			})
		},

		/**
		 * マップの切り替え処理
		 */
		switchMap (areaNo) {
			this.selectMap = areaNo
			this.initContainer()
			this.handleMap()
			this.bgCanvasStage.alpha = 0.7
			createjs.Tween.get(this.bgCanvasStage, { override: true })
				.to({ alpha: 1 }, 500, createjs.Ease.quadOut)
				.call(() => {
					this.stopAnimation()
				})
		},

		/**
		 * コンテナ初期化処理
		 */
		initContainer () {
			// カードやヒットエリアのオブジェクトを持つ可変コンテナの初期化
			if (this.variableContainer) {
				this.variableContainer.removeAllChildren()
			} else {
				this.variableContainer = new createjs.Container()
				this.canvasStage.addChild(this.variableContainer)
			}
			// 今日、明日、明後日のコンテナを作る
			for (let i = 0; i < PERIOD; i++) {
				const container = new createjs.Container()
				container.mouseEnabled = false	// コンテナのマウスイベント停止
				if (i !== this.dateNum) container.visible = false	// 選択中以外のコンテナを非表示に
				this.variableContainer.addChild(container)

				// コンテナ配列の初期化
				this.containerArray[i] = {}
				this.containerArray[i].container = container
				this.containerArray[i].cardArray = []

				// NOTE: カード表示領域の初期化
				for (let j = 0, len = this.computedMapData.length; j < len; j++) {
					const array = []
					array[0] = this.computedMapData[j].isUnder	// 地名を下にするかのフラグ
					array[1] = this.computedMapData[j].cityName	// 地名

					// NOTE: APIから取得したデータから以下の項目を取得
					const prop = this.computedMapData[j].forecasts[i]	// 該当のデータを取り出す

					// 天気コード
					let wetherCode = prop.weather.code
					// NOTE: アイコンが同じなので「〇一時〇」の天気コードを「〇時々〇」に書き換える
					switch (prop.weather.code) {
						case '102':
							wetherCode = '103'
							break
						case '104':
							wetherCode = '105'
							break
						case '202':
							wetherCode = '203'
							break
						case '204':
							wetherCode = '205'
							break
						case '309':
							wetherCode = '303'
							break
						case '409':
							wetherCode = '403'
							break
					}
					array.push(this.queue.getResult(`tenki_${wetherCode}`))

					// 降水確率
					const rain = prop.weather.precipitation
					const objRain = { 'rain': rain, 'rainIcon': this.queue.getResult('tenki_icon'), 'perIcon': this.queue.getResult('par') }
					array.push(objRain)

					// 最高気温
					array.push(prop.temperature.max)

					// 最低気温
					array.push(prop.temperature.min)

					// 最大風速
					const wind = prop.wind.speed
					const roundWind = Math.round(Number(wind))
					let windStr = ''
					let windColor = ''
					switch (true) {
						case roundWind >= 15:
							windStr = 'wind_arrow_lv4'
							windColor = SPEED_LEVEL4
							break
						case roundWind >= 10:
							windStr = 'wind_arrow_lv3'
							windColor = SPEED_LEVEL3
							break
						case roundWind >= 5:
							windStr = 'wind_arrow_lv2'
							windColor = SPEED_LEVEL2
							break
						case roundWind >= 0:
							windStr = 'wind_arrow_lv1'
							windColor = SPEED_LEVEL1
							break
					}
					const objWindSpeed = { 'wind': wind, 'windIcon': this.queue.getResult(windStr), 'windColor': windColor }
					array.push(objWindSpeed)

					// 最大風速時風向
					array.push(prop.wind.direction.code)

					// 気圧予報レベル
					let pressure = prop.pressure.level
					if (pressure === '0' || Number(pressure) === 0) pressure = '1'
					array.push(this.queue.getResult(`map_kiatu_icon_lv${pressure}`))

					// 最小湿度
					const humidity = Number(prop.humidity.min)
					let humidityStr = ''
					switch (true) {
						case humidity >= 90:
							humidityStr = 'wet90_99'
							break
						case humidity >= 85:
							humidityStr = 'wet85_90'
							break
						case humidity >= 80:
							humidityStr = 'wet80_85'
							break
						case humidity >= 75:
							humidityStr = 'wet75_80'
							break
						case humidity >= 70:
							humidityStr = 'wet70_75'
							break
						case humidity >= 65:
							humidityStr = 'wet65_70'
							break
						case humidity >= 60:
							humidityStr = 'wet60_65'
							break
						case humidity >= 55:
							humidityStr = 'wet55_60'
							break
						case humidity >= 50:
							humidityStr = 'wet50_55'
							break
						case humidity >= 45:
							humidityStr = 'wet45_50'
							break
						case humidity >= 40:
							humidityStr = 'wet40_45'
							break
						case humidity >= 35:
							humidityStr = 'wet35_40'
							break
						case humidity >= 30:
							humidityStr = 'wet30_35'
							break
						case humidity >= 25:
							humidityStr = 'wet25_30'
							break
						case humidity >= 20:
							humidityStr = 'wet20_25'
							break
						case humidity >= 0:
							humidityStr = 'wet00_20'
							break
					}
					const objHumidity = { 'humidity': prop.humidity.min, 'humidityImg': this.queue.getResult(humidityStr), 'kaitekiIcon': this.queue.getResult('kaiteki40to65') }
					array.push(objHumidity)

					// カード作成
					const card = this.makeCard(array[0], array[1], array[2], array[3], array[4], array[5], array[6], array[7], array[8], array[9])
					card.x = Math.round(this.computedMapData[j].x * this.scale)
					card.y = Math.round(this.computedMapData[j].y * this.scale)

					container.addChild(card)
					this.containerArray[i].cardArray[j] = card
				}
			}
			// イベントハンドラの登録をまとめて行う
			this.initStageEvent()
		},

		/**
		 * イベントの登録処理
		 */
		initStageEvent () {
			// NOTE: canvasをelementに指定すると動作しないので、ラッパーDOM要素をelementに設定
			this.eventDetector = this.$ons.GestureDetector(this.weatherMapWrapper)

			// NOTE: drag系のイベントをハンドルするようにした（swipeだとChromeのプレビューで動かなく実機で若干スムーズではなくなる）
			// detector.on('swipedown', (e) => {
			this.eventDetector.on('dragdown', (e) => {
				if (!this.isAnimation) {
					let num = this.elementNum + 1
					if (num >= ELEMENT_MAX) num = 0
					this.changeElement(num)
				}
			})
			// detector.on('swipeup', (e) => {
			this.eventDetector.on('dragup', (e) => {
				if (!this.isAnimation) {
					let num = this.elementNum - 1
					if (num < 0) num = ELEMENT_MAX - 1
					this.changeElement(num)
				}
			})
			// detector.on('swipeleft', (e) => {
			this.eventDetector.on('dragleft', (e) => {
				if (!this.isAnimation) {
					let num = this.dateNum + 1
					if (num >= PERIOD) num = 0
					this.chageDate(num, true)
				}
			})
			// detector.on('swiperight', (e) => {
			this.eventDetector.on('dragright', (e) => {
				if (!this.isAnimation) {
					let num = this.dateNum - 1
					if (num < 0) num = PERIOD - 1
					this.chageDate(num, true)
				}
			})

			// 地方マップから全国に切り替えるイベント
			this.eventDetector.on('tap', (e) => {
				if (!this.isAnimation && this.selectMap !== 0) {
					this.startAnimation()
					this.switchMap(0)
				}
			})
		},

		/**
		 * 表示切替ボタンの初期化
		 */
		initButton () {
			// 日付ボタン
			for (let i = 0; i < PERIOD; i++) {
				const button = this.makeButton(`btn_date${i}_on`, `btn_date${i}_off`)
				button.x = Math.round(((i * 189) + 37) * this.scale)
				button.y = Math.round(136 * this.scale)
				if (i !== this.dateNum) this.buttonPassive(button)
				else this.buttonActive(button)

				this.canvasStage.addChild(button)
				this.dateButtonArray[i] = button

				button.addEventListener('click', (e) => {
					if (i !== this.dateNum) this.chageDate(i, false)
				})
			}

			// 要素ボタン
			for (let i = 0; i < ELEMENT_MAX; i++) {
				const button = this.makeButton(`btn_element${i}_on`, `btn_element${i}_off`)
				button.x = Math.round(((i * 114) + 37) * this.scale)
				button.y = Math.round(200 * this.scale)
				if (i !== this.elementNum) this.buttonPassive(button)
				else this.buttonActive(button)

				this.canvasStage.addChild(button)
				this.elementButtonArray[i] = button

				button.addEventListener('click', (e) => {
					if (i !== this.elementNum) this.changeElement(i)
				})
			}
		},

		/**
		 * カードコンテナを作成する関数
		 */
		makeCard (isUnder, place, weather, rain, maxTemperature, minTemperature, wind, windDirection, pressure, humidity) {
			// コンテナ作成
			const cardContainer = new createjs.Container()
			cardContainer.mouseEnabled = false	// コンテナのマウスイベント停止
			cardContainer.regX = 46 * this.scale
			cardContainer.regY = 65 * this.scale

			// 地名
			const placeName = new createjs.Sprite(this.celSpriteSheet)
			placeName.gotoAndStop(place)
			placeName.x = Math.round(-5 * this.scale)
			placeName.y = Math.round(-3 * this.scale)
			cardContainer.addChild(placeName)

			let bmp = null
			let text = null
			let shape = null
			for (let i = 0, len = ELEMENT_MAX; i < len; i++) {
				const container = new createjs.Container()
				// 表示するカテゴリ以外のコンテナは非表示
				if (i !== this.elementNum) container.visible = false

				switch (true) {
					case i === 0:	// 気圧
						// 気圧画像
						bmp = new createjs.Bitmap(pressure)
						bmp.x = Math.round(2 * this.scale)
						if (isUnder) bmp.y = Math.round(2 * this.scale)
						else bmp.y = Math.round(34 * this.scale)
						container.addChild(bmp)
						break
					case i === 1:	// 天気
						// 天気画像
						bmp = new createjs.Bitmap(weather)
						bmp.x = Math.round(11 * this.scale)
						if (isUnder) bmp.y = Math.round(4 * this.scale)
						else bmp.y = Math.round(36 * this.scale)
						container.addChild(bmp)

						// 降水確率アイコン
						bmp = new createjs.Bitmap(rain.rainIcon)
						bmp.x = Math.round(4 * this.scale)
						if (isUnder) bmp.y = Math.round(64 * this.scale)
						else bmp.y = Math.round(96 * this.scale)
						container.addChild(bmp)

						// パーセントアイコン
						bmp = new createjs.Bitmap(rain.perIcon)
						bmp.x = Math.round(63 * this.scale)
						if (isUnder) bmp.y = Math.round(68 * this.scale)
						else bmp.y = Math.round(100 * this.scale)
						container.addChild(bmp)

						// 降水確率テキスト
						text = new createjs.Text(rain.rain, `${20 * this.scale}px ${cmnConst.FAMILY_PRIMARY}`, TEXT_PRIMARY)
						text.textAlign = 'right'
						text.textBaseline = 'bottom'
						text.x = Math.round(62 * this.scale)
						if (isUnder) text.y = Math.round(85 * this.scale)
						else text.y = Math.round(117 * this.scale)
						container.addChild(text)
						break
					case i === 2:	// 気温
						// 最高気温背景シェイプ
						shape = new createjs.Shape()
						shape.graphics.beginFill(BACKGROUND_TEMPERATURE_BEST).drawRect(0, 0, 82 * this.scale, 37 * this.scale)
						shape.x = 0
						if (isUnder) shape.y = Math.round(9 * this.scale)
						else shape.y = Math.round(37 * this.scale)
						container.addChild(shape)

						// 最低気温背景シェイプ
						shape = new createjs.Shape()
						shape.graphics.beginFill(BACKGROUND_TEMPERATURE_WORST).drawRect(0, 0, 82 * this.scale, 37 * this.scale)
						shape.x = 0
						if (isUnder) shape.y = Math.round(46 * this.scale)
						else shape.y = Math.round(74 * this.scale)
						container.addChild(shape)

						// 最高気温テキスト
						const maxText = new createjs.Text(`${maxTemperature}℃`, `${24 * this.scale}px ${cmnConst.FAMILY_PRIMARY}`, TEXT_SECONDARY)
						maxText.textAlign = 'center'
						maxText.textBaseline = 'top'
						maxText.x = Math.round(41 * this.scale)
						if (isUnder) maxText.y = Math.round(15 * this.scale)
						else maxText.y = Math.round(44 * this.scale)

						// 最低気温テキスト
						const minText = new createjs.Text(`${minTemperature}℃`, `${24 * this.scale}px ${cmnConst.FAMILY_PRIMARY}`, TEXT_SECONDARY)
						minText.textAlign = 'center'
						minText.textBaseline = 'top'
						minText.x = Math.round(41 * this.scale)
						if (isUnder) minText.y = Math.round(52 * this.scale)
						else minText.y = Math.round(80 * this.scale)

						container.addChild(maxText)
						container.addChild(minText)
						break
					case i === 3:	// 風向
						// 風向きアイコン
						bmp = new createjs.Bitmap(wind.windIcon)
						bmp.regX = bmp.getBounds().width / 2
						bmp.regY = bmp.getBounds().height / 2
						bmp.rotation = (Number(windDirection) - 1) * (360 / 16) + 180
						bmp.x = Math.round(41 * this.scale)
						if (isUnder) bmp.y = Math.round(28 * this.scale)
						else bmp.y = Math.round(60 * this.scale)
						container.addChild(bmp)

						// 風速の色
						shape = new createjs.Shape()
						shape.graphics.beginFill(wind.windColor).drawRect(0, 0, 10 * this.scale, 20 * this.scale)
						shape.x = 0
						if (isUnder) shape.y = Math.round(59 * this.scale)
						else shape.y = Math.round(91 * this.scale)
						container.addChild(shape)

						// 風速数値テキスト
						text = new createjs.Text(Math.round(Number(wind.wind)), `${24 * this.scale}px ${cmnConst.FAMILY_PRIMARY}`, TEXT_PRIMARY)
						text.lineHeight = 1
						text.textAlign = 'right'
						text.textBaseline = 'bottom'
						text.x = Math.round(40 * this.scale)
						if (isUnder) text.y = Math.round(80 * this.scale)
						else text.y = Math.round(112 * this.scale)
						container.addChild(text)

						// 風速単位テキスト
						text = new createjs.Text('m/s', `${20 * this.scale}px ${cmnConst.FAMILY_PRIMARY}`, TEXT_PRIMARY)
						text.lineHeight = 1
						text.textAlign = 'left'
						text.textBaseline = 'bottom'
						text.x = Math.round(40 * this.scale)
						if (isUnder) text.y = Math.round(80 * this.scale)
						else text.y = Math.round(112 * this.scale)
						container.addChild(text)
						break
					case i === 4:	// 湿度
						// 湿度背景画像
						bmp = new createjs.Bitmap(humidity.humidityImg)
						bmp.x = Math.round(2 * this.scale)
						if (isUnder) bmp.y = Math.round(2 * this.scale)
						else bmp.y = Math.round(34 * this.scale)
						container.addChild(bmp)

						// 湿度パーセントアイコン
						bmp = new createjs.Bitmap(rain.perIcon)
						bmp.x = Math.round(57 * this.scale)
						if (isUnder) bmp.y = Math.round(62 * this.scale)
						else bmp.y = Math.round(94 * this.scale)
						container.addChild(bmp)

						// 湿度パーセントテキスト
						text = new createjs.Text(Math.round(Number(humidity.humidity)), `${32 * this.scale}px ${cmnConst.FAMILY_PRIMARY}`, TEXT_PRIMARY)
						text.textAlign = 'center'
						text.textBaseline = 'top'
						text.x = Math.round(41 * this.scale)
						if (isUnder) text.y = Math.round(20 * this.scale)
						else text.y = Math.round(52 * this.scale)
						container.addChild(text)

						// 快適アイコン判定
						if (Math.round(Number(humidity.humidity)) >= 40 && Math.round(Number(humidity.humidity)) <= 65) {
							// 快適アイコン
							bmp = new createjs.Bitmap(humidity.kaitekiIcon)
							bmp.x = Math.round(13 * this.scale)
							if (isUnder) bmp.y = Math.round(12 * this.scale)
							else bmp.y = Math.round(44 * this.scale)
							container.addChild(bmp)
						}
						break
				}
				cardContainer.addChild(container)
			}
			return cardContainer
		},

		/**
		 * ボタンのコンテナ作成関数
		 */
		makeButton (onImg, offImg) {
			// コンテナ作成
			const buttonContainer = new createjs.Container()
			buttonContainer.mouseEnabled = true	// コンテナのマウスイベント許可

			// onボタン
			const onBmp = new createjs.Sprite(this.buttonSpriteSheet)
			onBmp.gotoAndStop(onImg)
			onBmp.visible = false
			buttonContainer.addChild(onBmp)

			// offボタン
			const offBmp = new createjs.Sprite(this.buttonSpriteSheet)
			offBmp.gotoAndStop(offImg)
			buttonContainer.addChild(offBmp)

			return buttonContainer
		},

		/**
		 * ボタン内の画像をアクティブに切り替える関数
		 */
		buttonActive (button) {
			button.children[0].visible = true
			button.children[1].visible = false
		},

		/**
		 * ボタン内の画像を非アクティブに切り替える関数
		 */
		buttonPassive (button) {
			button.children[0].visible = false
			button.children[1].visible = true
		},

		/**
		 * 日付変更の描画処理
		 */
		chageDate (num, isSwipe) {
			// 処理しないケース
			if (num < 0 || num >= PERIOD || this.isAnimation) return false

			this.startAnimation()
			let toX = -150 * this.scale
			let prevNum = Number(this.dateNum)
			this.dateNum = num

			// 右方向（+）に移動するケースの判定
			if (prevNum > this.dateNum) toX *= -1
			if (isSwipe && prevNum === (PERIOD - 1) && this.dateNum === 0) toX *= -1
			if (isSwipe && prevNum === 0 && this.dateNum === (PERIOD - 1)) toX *= -1

			// アクティブ・非アクティブなボタンの更新
			for (let i = 0; i < PERIOD; i++) {
				if (i === this.dateNum) this.buttonActive(this.dateButtonArray[i])
				else this.buttonPassive(this.dateButtonArray[i])
			}

			// コンテナーのZ-Indexを変更
			this.canvasStage.setChildIndex(this.containerArray[this.dateNum].container, this.canvasStage.numChildren - 1)

			this.containerArray[this.dateNum].container.x = -toX
			this.containerArray[this.dateNum].container.alpha = 0
			this.containerArray[this.dateNum].container.visible = true

			// ひとつ前のコンテナを消す
			createjs.Tween.get(this.containerArray[prevNum].container, { override: true })
				.to({ x: toX, alpha: 0 }, 400, createjs.Ease.quadOut)
				.call(() => {
					this.containerArray[prevNum].container.visible = false
				})

			// 選択されたコンテナを表示
			createjs.Tween.get(this.containerArray[this.dateNum].container, { override: true })
				.wait(50).to({ x: 0, alpha: 1 }, 400, createjs.Ease.quadOut)
				.call(() => {
					// NOTE: アニメーションが終わったらフラグを消してframerateを1に戻す
					this.stopAnimation()
				})
		},

		/**
		 * 要素変更の描画処理
		 */
		changeElement (num) {
			// 処理しないケース
			if (this.elementNum < 0 || this.elementNum >= ELEMENT_MAX || this.isAnimation) return false

			this.startAnimation()
			this.elementNum = num

			// アクティブ・非アクティブなボタンの更新
			for (let i = 0; i < ELEMENT_MAX; i++) {
				if (i === this.elementNum) this.buttonActive(this.elementButtonArray[i])
				else this.buttonPassive(this.elementButtonArray[i])
			}

			// カードの描画
			for (let i = 0; i < PERIOD; i++) {
				for (let j = 0, len = this.computedMapData.length; j < len; j++) {
					if (this.dateNum === i) {
						this.rotateCard(this.containerArray[i].cardArray[j], () => {
							this.stopAnimation()
						})
					} else {
						this.changeCard(this.containerArray[i].cardArray[j])
					}
				}
			}
		},

		/**
		 * アニメーションを開始する関数
		 */
		startAnimation () {
			// NOTE: アニメーション開始時はフラグを立ててframerateを上げる
			this.isAnimation = true
			createjs.Ticker.addEventListener('tick', this.loop)
		},

		/**
		 * アニメーションを停止する関数
		 */
		stopAnimation () {
			// NOTE: アニメーションが終わったらフラグを消してframerateを1に戻す
			this.isAnimation = false
			createjs.Ticker.removeEventListener('tick', this.loop)
		},

		/**
		 * カードの回転アクション
		 */
		rotateCard (card, callback) {
			createjs.Tween.get(card, { override: true })
				.to({ scaleY: 0 }, 250, createjs.Ease.quadIn)
				.call(() => {
					// カードを該当要素のものに変更
					this.changeCard(card)
				})
				.to({ scaleY: 1.07 }, 250, createjs.Ease.quadOut)
				.to({ scaleY: 1 }, 500, createjs.Ease.quadOut)
				.call(() => {
					if (callback) callback()
				})
		},

		/**
		 * カードの描画変更処理
		 */
		changeCard (card) {
			for (let i = 0; i < ELEMENT_MAX; i++) {
				// Cardコンテナの最初は地名なので地名分を足したものが要素カードの該当Indexとなる
				if (i !== this.elementNum) card.children[i + 1].visible = false
				else card.children[i + 1].visible = true
			}
		},

		/**
		 * 画面リサイズ処理
		 */
		onResize () {
			let width = cmnConst.INIT_STAGE_WIDTH
			let height = cmnConst.INIT_STAGE_HEIGHT
			const propWidth = this.weatherMapWrapper.offsetWidth
			if (window.devicePixelRatio === 3) {
				width = 960
				height = 1704
				this.scale = 1.5
				this.resolution = '@3x'
			} else {
				this.scale = 1
				this.resolution = '@2x'
			}
			const zoom = propWidth / width
			this.canvas.setAttribute('width', `${width}px`)
			this.canvas.setAttribute('height', `${height}px`)
			this.canvas.setAttribute('style', `transform: scale(${zoom}, ${zoom}); -webkit-transform: scale(${zoom}, ${zoom});`)
			this.bgCanvas.setAttribute('width', `${width}px`)
			this.bgCanvas.setAttribute('height', `${height}px`)
			this.bgCanvas.setAttribute('style', `transform: scale(${zoom}, ${zoom}); -webkit-transform: scale(${zoom}, ${zoom}); visibility: visible;`)
			if (this.viewMode === 'sugotoku') {
				const containerHeight = this.canvas.height * zoom
				this.weatherMapWrapper.setAttribute('style', `padding-top: ${containerHeight}px;`)
				// スゴ得の場合、コンテンツの高さをemitする。
				this.$emit('containerHeight', containerHeight - cmnConst.HEADER_HEIGHT)
			} else if (this.isWebview) {
				this.weatherMapWrapper.setAttribute('style', `padding-top: ${(window.innerHeight + cmnConst.HEADER_HEIGHT) / propWidth * 100}%;`)
				// プレミアムユーザーフラグ
				const isPremium = this.premiumType && this.premiumType.premium_type_code > 1
				console.log('isPremium: ', isPremium)
			} else {
				this.weatherMapWrapper.setAttribute('style', `padding-top: ${window.innerHeight / propWidth * 100}%;`)
			}
		}
	},

	beforeDestroy () {
		createjs.Ticker.removeEventListener('tick', this.loop)
		createjs.Tween.removeAllTweens()
		for (let i = 0, len = this.dateButtonArray.length; i < len; i++) {
			this.dateButtonArray[i].removeAllEventListeners('click')
		}
		for (let i = 0, len = this.elementButtonArray.length; i < len; i++) {
			this.elementButtonArray[i].removeAllEventListeners('click')
		}
		for (let i = 0, len = this.hitAreaArray.length; i < len; i++) {
			this.hitAreaArray[i].removeAllEventListeners('click')
		}
		if (this.eventDetector) this.eventDetector.dispose()
		this.canvasStage.removeAllChildren()
		this.bgCanvasStage.removeAllChildren()
		this.canvasStage.clear()
		this.bgCanvasStage.clear()

		// スゴ得の場合、コンテンツの高さをautoでemitする。
		if (this.viewMode === 'sugotoku') this.$emit('containerHeight', 'auto')
	}
}
</script>

<style lang='scss' scoped>
.weather-map {
	// TODO: 上部にスペースができている問題について暫定対応として基点をヘッダー下ではなく画面上部（position: absoluteのtop: 0）にした
	&__wrapper {
		width: 100%;
		max-width: 640px;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0 auto;
		overflow: hidden;
	}

	&__canvas {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		display: block;
		transform-origin: 0 0;
		backface-visibility: hidden;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	&__background {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		display: block;
		transform-origin: 0 0;
		backface-visibility: hidden;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
}

// TODO: アニメーションの仕様が決まったら変更
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
</style>
