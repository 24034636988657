<template>
	<!-- TODO: 表示時にアニメーションがあったほうがいいか検討 -->
	<div class="common-error-alert">
		<div @click="reload" class="common-error-alert__inner">
			<img src="@/assets/img/data_error_img.png" alt="データが正常に取得できませんでした。電波の良いところで再度お試しください。">
		</div>
	</div>
</template>

<script>

export default {
	name: 'CommonErrorAlert',
	methods: {
		reload () {
			this.$emit('reload')
		}
	}
}
</script>

<style lang="scss">
// 変数定義SASS
@import "@/assets/sass/variable";

.common-error-alert {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url('~@/assets/img/relord_bg.png') center / contain repeat-y;
	width: 100%;
	height: 100%;
	z-index: 9999;
	&__inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		& > img {
			width: 71.875%;
		}
	}
}
</style>
