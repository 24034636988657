<template>
	<div :class="[
		'redraw-progress',
		{ 'is-fixed': isFixed }
	]">
		<div class="redraw-progress__inner">
			<v-ons-progress-circular indeterminate></v-ons-progress-circular>
		</div>
	</div>
</template>

<script>

export default {
	name: 'RedrawProgress',
	props: {
		isFixed: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss">
// 変数定義SASS
@import "@/assets/sass/variable";

.redraw-progress {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: rgba($_black, .4);
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: 9999;
	pointer-events: none;
	&__inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 12% 18%;
		border-radius: 20px;
		background: rgba($_white, .4);
	}
	&.is-fixed {
		position: fixed;
		top: 0;
		left: 0;
	}
}
</style>
